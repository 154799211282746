<template>
  <ItemWrapper>
    <v-row justify-start wrap>
      <v-col cols="12" lg="6">
        <AppCard
          :title="`${$t('Ticket Queue')} ${ticketQueue.name || ''}`"
          :prev-route="prevRoute"
          :loading="pending.getQueue"
        >
          <template #titleActions>
            <!-- TODO: add edit mode -->
            <v-btn
              :to="{ name: 'ticketQueues-edit', params: { id: ticketQueue.id } }"
              color="success"
              fab
              small
              dark
              class="mr-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>

          <div v-if="ticketQueue && ticketQueue.id" class="text--disabled">
            <p v-if="ticketQueue.user">
              <b>Moderator:</b>
              <br />
              <span>{{ ticketQueue.user.firstname }} {{ ticketQueue.user.lastname }}</span>
            </p>
          </div>

          <v-list v-if="ticketQueue && ticketQueue.tickets" dense>
            <template v-for="ticket in ticketQueue.tickets">
              <v-list-item :key="`client-ticket-${ticket.id}`" class="px-0">
                <div class="d-flex justify-space-between w-100">
                  <div>
                    <v-chip x-small color="primary" class="mr-1">{{ ticket.priority }}</v-chip>
                    <span>{{ ticket.subject }}</span>
                  </div>
                  <v-btn :to="{ name: 'tickets-item', params: { id: ticket.id } }" icon>
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-list-item>
            </template>
          </v-list>
        </AppCard>
      </v-col>

      <v-col cols="12" sm="6">
        <AppCard :loading="pending.getQueue" :title="$t('Users')">
          <v-chip-group v-if="ticketQueue.users && ticketQueue.users.length >= 1" column>
            <v-chip
              v-for="user in ticketQueue.users"
              :key="user.id"
              :to="{ name: 'users-item', params: { id: user.id } }"
            >
              {{ user.firstname }} {{ user.lastname }}
            </v-chip>
          </v-chip-group>
          <p v-else>Ticket queue has no users</p>
        </AppCard>
      </v-col>
    </v-row>
  </ItemWrapper>
</template>

<script>
import { defineComponent, onBeforeUnmount, computed, ref } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard'
import ItemWrapper from '@/layouts/ItemWrapper'

export default defineComponent({
  name: 'ViewTicketQueue',
  components: {
    AppCard,
    ItemWrapper,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const prevRoute = ref('/TicketQueues')

    // store
    const pending = computed(_ => $store.state.ticketQueue.pending)
    const ticketQueue = computed(_ => $store.state.ticketQueue.ticketQueue)
    const getTicketQueue = _ => $store.dispatch('ticketQueue/getTicketQueue')
    const clearTicketQueue = _ => $store.commit('ticketQueue/CLEAR_TICKET_QUEUE')

    getTicketQueue()
      .then(r => r)
      .catch(error => {
        if (error.response.status === 404) {
          $router.push({ name: 'notFound' })
        }
      })

    onBeforeUnmount(_ => {
      clearTicketQueue()
    })

    return {
      prevRoute,
      pending,
      ticketQueue,
    }
  },
}) //
</script>
